<template>
<div id="page-traspasos_salida">
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height>
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="Salida Física de Mercancía" class="elevation-1 px-5 py-3">


          <v-card-text>
            <v-row>
              <v-col lg="4" md="4" sm="4">
                <v-text-field label="Ingrese Código de Orden de Salida" v-model="codigo" :rules="[rules.required]"
                  @keyup.enter="codigo = codigo.toString().toUpperCase(); buscarCodigo()"
                  @input="upperCaseInput('codigo')"  ref="ingresacodigo" :readonly="model._id!=''">
                </v-text-field>
              </v-col>
              <v-col lg="2" md="2" sm="2" v-if="model._id==''">
                <v-btn small color="info" @click="buscarCodigo()">
                  <v-icon class="white--text">search</v-icon> Buscar
                </v-btn>
              </v-col>

              <v-col lg="4" md="4" sm="4" v-if="model._id!=''">
                <v-text-field label="Ingrese Código de Artículo" v-model="codigo_articulo" :rules="[rules.required]"
                  @keyup.enter="codigo_articulo = codigo_articulo.toString().toUpperCase(); buscarArticulo()"
                  @input="upperCaseInput('codigo_articulo')" ref="ingresaarticulo">
                </v-text-field>
              </v-col>
              <v-col lg="2" md="2" sm="2" v-if="model._id!=''">
                <v-btn small color="info" @click="buscarArticulo()">
                  <v-icon class="white--text">search</v-icon> Buscar
                </v-btn>
              </v-col>
            </v-row>
            <div id="datagrid">

              <v-client-table ref="vuetable" :data="registros.items" :columns="columns" :options="options"
                class="elevation-2 pa-2">

                <template slot="beforeTable">
                  <div style="text-align:right" v-show="registros.items.length>0">
                    <v-btn small text outlined color="green darken-2"
                        @click.native="aceptar_todo()" title="Aceptar Todo" v-tippy>
                        <v-icon>done</v-icon> Aceptar Todo
                    </v-btn>
                  </div><br>
                </template>
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>
                <template slot="codigo_articulo" slot-scope="props">
                  {{ props.row.codigo_articulo.join(", ") }}
                </template>
                <template slot="actions" slot-scope="props">
                  <v-btn small fab dark color="green darken-2"
                      @click.native="mostrarEditar(props.row)" title="Aceptar" v-tippy>
                      <v-icon>done</v-icon>
                  </v-btn> &nbsp;
                  <v-btn small fab dark color="blue darken-2"
                      @click.native="mostrarAgregar(props.row)" title="Agregar" v-tippy>
                      <v-icon>add</v-icon>
                  </v-btn>
                </template>
              </v-client-table>

              <br>
              <v-row justify="center" v-if="model._id!=''">
                  <v-btn color="success" dark @click="finalizarSalida()">
                      <v-icon>done_all</v-icon> Finalizar Salida
                    </v-btn>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <v-btn color="info" @click="buscarOtro()" v-if="model._id!=''">
                      <v-icon class="white--text">search</v-icon> Buscar Otra Orden
                    </v-btn>
              </v-row>

            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modalEditar" max-width="500px">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">edit</v-icon>&nbsp;
          <span class="subheading">
            <strong>Cantidad Real de Salida</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalEditar = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container>
            <v-row justify="center">
              <v-col sm="3" md="3" lg="3" style="text-align:right !important;">
                <v-btn small color="grey" @click.native="(cantidad_editable!=null && cantidad_editable>0)?cantidad_editable=parseFloat(cantidad_editable)-1:cantidad_editable=0">
                  <v-icon>remove</v-icon>
                </v-btn>
              </v-col>
              <v-col sm="3" md="3" lg="3" style="text-align:center !important;">
                <v-text-field label="Cantidad" v-model="cantidad_editable" ref="ingresacantidad"
                  :rules="[validaCantidadFloat]" v-on:keydown.enter.prevent='1' @keyup.enter="editar()"></v-text-field>
              </v-col>
              <v-col sm="3" md="3" lg="3" style="text-align:left !important;">
                <v-btn small color="grey" @click.native="(cantidad_editable!=null && cantidad_editable>=0)?cantidad_editable=parseFloat(cantidad_editable)+1:cantidad_editable=0">
                  <v-icon>add</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-btn @click.native="modalEditar = false">
                <v-icon>cancel</v-icon> Cancelar
              </v-btn>
              &nbsp;&nbsp;
              <v-btn color="success" @click.native="editar()">
                <v-icon>done</v-icon> Aceptar
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

    </v-card>
  </v-dialog>

  <!-- MODAL AGREGAR -->
  <v-dialog v-model="modalAgregar" max-width="500px">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">edit</v-icon>&nbsp;
          <span class="subheading">
            <strong>Cantidad a Agregar</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalAgregar = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container>
            <v-row justify="center">
              <v-col sm="3" md="3" lg="3" style="text-align:right !important;">
                <v-btn small color="grey" @click.native="(cantidad_agregar!=null && cantidad_agregar>0)?cantidad_agregar=parseFloat(cantidad_agregar)-1:cantidad_agregar=0">
                  <v-icon>remove</v-icon>
                </v-btn>
              </v-col>
              <v-col sm="3" md="3" lg="3" style="text-align:center !important;">
                <v-text-field label="Cantidad" v-model="cantidad_agregar" ref="ingresacantidadagregar"
                  :rules="[validaCantidadFloat]" v-on:keydown.enter.prevent='1' @keyup.enter="agregar()"></v-text-field>
              </v-col>
              <v-col sm="3" md="3" lg="3" style="text-align:left !important;">
                <v-btn small color="grey" @click.native="(cantidad_agregar!=null && cantidad_agregar>=0)?cantidad_agregar=parseFloat(cantidad_agregar)+1:cantidad_agregar=0">
                  <v-icon>add</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-btn @click.native="modalAgregar = false">
                <v-icon>cancel</v-icon> Cancelar
              </v-btn>
              &nbsp;&nbsp;
              <v-btn color="success" @click.native="agregar()">
                <v-icon>done</v-icon> Aceptar
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

    </v-card>
  </v-dialog>

  <!-- MODAL BUSCAR -->
  <v-dialog v-model="modalBuscar" max-width="50%" persistent>
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Buscar artículo</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon class="close_modal">
          <v-icon class="white--text"></v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formajuste" lazy-validation>
          <v-container grid-list-md>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-autocomplete v-model="buscar_codigo_articulo" :items="articulos" :hide-no-data="true"
                    :loading="isLoading" ref="selectarticulo"
                    :search-input.sync="search"
                    placeholder="Ingrese nombre de artículo para buscar"
                    prepend-icon="search" v-on:keydown.enter.prevent='1' 
                    :hide-selected="true" item-text="nombre" item-value="codigo_barras" @change="seleccionarArticulo()"
                    label="Artículo" :rules="[rules.required]">
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="cerrarModal()">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- MODAL OBSERVACION -->
  <v-dialog v-model="modalObservacion" max-width="50%" persistent>
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Agregar Observaciones</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon class="close_modal">
          <v-icon class="white--text"></v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formobservacion" lazy-validation>
          <v-container grid-list-md>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-textarea outlined label="Observaciones" rows="4" v-model="observacion" :rules="[rules.required]" ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modalObservacion=false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        &nbsp;&nbsp;
        <v-btn color="success" @click.native="finalizarObservacion()">
          <v-icon>done</v-icon> Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</div>
</template>

<script>

export default {
  components: {},
  mounted: function() {

    if (!this.verificaPermiso('s.inventarios.salidas')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },

  created: function() {
    this.sucursal_activa = this.$local_storage.get("sb_sucursal","0");
    if(this.sucursal_activa == "0"){ //Si en otra pestaña cambiaron a sucursal TODAS recargamos la pagina
        this.$router.go();            
    }
    this.$nextTick(() => this.$refs.ingresacodigo.focus());
  },
  watch: {

    search (val) {

        //Si no ha escrito 4 letras o mas
        if (val == null || val.length<4 ) return

        // Si todavía esta buscando
        if (this.isLoading) return

        this.isLoading = true

        let data = {
                    "selector": {
                      "type":"articulos",
                        "nombre": {"$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")}
                    },
                    /*"fields": [
                        "nombre", "estatus"
                    ]*/
                    "use_index":"_design/7d6652dbf4254f0af6f48c9a54fe2f8afe9f3f0f"
                    };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',data)
                .then(response => {
                    if (response.data.docs.length > 0){
                        this.articulos = response.data.docs;
                    }else
                        this.articulos = [];

                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los articulos.",
                        footer: ""
                    });

                }).then(()=>{ this.isLoading=false; });
      },
  },
  data() {
    return {
      sucursal_activa:"",
      edicion_directa:false,
      isLoading:false,
      modalBuscar:false,
      modalObservacion:false,
      observacion:"",
      codigo: "",
      tipo_orden: "", //Traspaso, Venta, ...(las que se agreguen)
      codigo_articulo:"",
      articuloEditable:{},
      cantidad_editable:"",
      cantidad_agregar:"",
      props_paginacion: {infiniteHandler:this.infiniteHandler, total_registros: 0},
      fab: [],
      name: "datagrid",
      columns: [
        "codigo_articulo",
        "nombre_articulo",
        "cantidad",
        "cantidad_salida",
        "actions"
      ],
      show_loading: true,
      options: {
        filterable: false,
        headings: {
          "codigo_articulo":"Código",
          "nombre_articulo":"Artículo",
          "cantidad":"Cantidad",
          "cantidad_salida":"Cantidad Real Salida",
          actions: "Editar Cantidad"
        },
        rowClassCallback: function(row) { return row.cantidad==row.cantidad_salida?'bg-bien':row.cantidad_salida==0?'bg-default':'bg-mal'},

      },
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Inventario",
        href: "",
          disabled: true,
        },
        {
          text: "Salida Física de Mercancía",
          href: ""
        }
      ],

      modalEditar: false,
      modalAgregar: false,
      update: false,
      articulos:[],
      buscar_codigo_articulo:"",
      model:{
            _id:"",
            _rev:"",
            type:"lotes_traspasos",
            clave_traspaso:"",
            id_sucursal_origen:"",
            nombre_sucursal_origen:"",
            id_sucursal_destino:"",
            nombre_sucursal_destino:"",
            articulos:[],
            usuario:"",
            fecha:"",
            fecha_finalizado:"",
            estatus:"", //Pendiente, En Proceso de Salida, Salida de Origen, En Proceso de Entrada, Recibido
            //Arreglo de lotes que se afectaron en sucursal destino,
            // esta opción se llena cuando se finaliza el traspaso en la vista de Traspasos
            lotes_destino: [],
        },
        articulo:{
            id_articulo:"",
            nombre_articulo:"",
            codigo_articulo:"",
            cantidad:"",
            lotes:[], //lotes que afecto el articulo en sucursal origen
            estatus:"Pendiente", //Pendiente, Salida (de origen), Recibido (en destino)
            cantidad_recibida: "",  //cantidad recibida en destino, ya que puede ser diferente
            lotes_destino:[], //Esta opción se llena cuando se le da entrada articulo por articulo en sucursal destino
        },
      rules: {
        required: v => !!v || "Este campo es requerido",
      },
      search: "",
      registros: {
        selected: [],
        items: []
      },

    };
  },
  methods: {
    upperCaseInput(fieldName) {
      // Guarda la posición actual del cursor
      const input = event.target;
      const start = input.selectionStart;
      const end = input.selectionEnd;

      // Transforma el valor del campo a mayúsculas
      const fieldValue = this[fieldName];
      const upperCaseValue = fieldValue.toUpperCase();

      // Actualiza el valor del campo en el modelo de datos
      this[fieldName] = upperCaseValue;

      // Actualiza el valor del input en la vista
      input.value = upperCaseValue;

      // Restablece la posición del cursor
      input.setSelectionRange(start, end);
    },
    seleccionarArticulo: function(){
      this.codigo_articulo = this.buscar_codigo_articulo[0]; //Buscamos con el primer codigo
      this.modalBuscar = false;
      this.buscarArticulo();
    },

    mostrarEditar: function(row){
      this.articuloEditable = row;
      this.cantidad_editable = row.cantidad;

      this.modalEditar = true;
      setTimeout(() => {
          this.$nextTick(() => this.$refs.ingresacantidad.focus());
      }, 100);

    },
    //Agrega la cantidad a la que ya se lleva
    mostrarAgregar: function(row){
      this.articuloEditable = row;
      this.cantidad_agregar = 1;
      this.modalAgregar = true;
      setTimeout(() => {
          this.$nextTick(() => this.$refs.ingresacantidadagregar.focus());
      }, 100);

    },

    marcarCorrecto: function(row){
      this.articuloEditable = row;
      this.cantidad_editable = row.cantidad;
      this.edicion_directa = true;
      this.editar();

    },

    editar: function(){
    
      if(this.edicion_directa == true || this.$refs.form.validate()) {
          if (this.articuloEditable.cantidad_salida.toString() != this.cantidad_editable.toString()) {          
              //TRASPASO
              if(this.tipo_orden == "Traspaso"){

                window.dialogLoader.show('Espere un momento por favor..');
                //Si el estatus del modelo esta en "Pediente" cambiarlo a "En Proceso de Salida"
                if(this.model.estatus == "Pendiente")
                  this.model.estatus = "En Proceso de Salida";

                this.model.articulos.forEach(e => {
                    if(e.id_articulo == this.articuloEditable.id_articulo){
                        e.cantidad_salida = parseFloat(this.cantidad_editable);
                    }
                });

                window.axios
                    .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
                    .then(response => {
                        this.model._rev = response.data.rev;
                        this.modalEditar = false;
                        this.modalAgregar = false;
                    })
                    .catch(error => {
                        this.$swal({
                                type: "error",
                                title: "¡Operación no Permitida!",
                                text: "Ocurrió un error al guardar el registro. Intente Nuevamente.",
                                footer: ""
                            });
                    }).then(() => {
                        window.dialogLoader.hide();
                        this.codigo_articulo="";
                        this.edicion_directa = false;
                        this.$nextTick(() => this.$refs.ingresaarticulo.focus());
                    });

              //VENTA
              } else if(this.tipo_orden == "Venta"){

                  window.dialogLoader.show('Espere un momento por favor..');
                  //Si el estatus del modelo esta en "Pediente" cambiarlo a "En Proceso de Salida"

                  this.model.articulos.forEach(e => {
                    if(e.id_articulo == this.articuloEditable.id_articulo){
                        e.cantidad_salida = parseFloat(this.cantidad_editable);
                    }
                });

                  window.axios
                      .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
                      .then(response => {
                          this.model._rev = response.data.rev;
                          this.modalEditar = false;
                          this.modalAgregar = false;
                      })
                      .catch(error => {
                          this.$swal({
                                  type: "error",
                                  title: "¡Operación no Permitida!",
                                  text: "Ocurrió un error al guardar el registro. Favor de comunicarse con soporte.",
                                  footer: ""
                              });
                      }).then(() => {
                          window.dialogLoader.hide();
                          this.codigo_articulo="";
                          this.edicion_directa = false;
                          this.$nextTick(() => this.$refs.ingresaarticulo.focus());
                      });

              }
          } else {
            this.modalEditar = false;
            this.modalAgregar = false;
            this.codigo_articulo="";
            this.$nextTick(() => this.$refs.ingresaarticulo.focus());
          }
      }
    },

    agregar: function(){      
      if(this.edicion_directa == true || this.$refs.form.validate()) {
          this.cantidad_editable = parseFloat(this.articuloEditable.cantidad_salida) + parseFloat(this.cantidad_agregar);
          this.editar();        
      }
    },

    aceptar_todo: function(){
                    
          //TRASPASO
          if(this.tipo_orden == "Traspaso"){

            window.dialogLoader.show('Espere un momento por favor..');
            //Si el estatus del modelo esta en "Pediente" cambiarlo a "En Proceso de Salida"
            if(this.model.estatus == "Pendiente")
              this.model.estatus = "En Proceso de Salida";

            this.model.articulos.forEach(e => {                
                e.cantidad_salida = parseFloat(e.cantidad);
            });

            window.axios
                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
                .then(response => {
                    this.model._rev = response.data.rev;                    
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al guardar el registro. Intente Nuevamente.",
                        footer: ""
                    });
                }).then(() => {
                    window.dialogLoader.hide();                                        
                });

          //VENTA
          } else if(this.tipo_orden == "Venta"){

              window.dialogLoader.show('Espere un momento por favor..');
              //Si el estatus del modelo esta en "Pediente" cambiarlo a "En Proceso de Salida"

              this.model.articulos.forEach(e => {                
                  e.cantidad_salida = parseFloat(e.cantidad);
            });

              window.axios
                  .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
                  .then(response => {
                      this.model._rev = response.data.rev;                      
                  })
                  .catch(error => {
                      this.$swal({
                          type: "error",
                          title: "¡Operación no Permitida!",
                          text: "Ocurrió un error al guardar el registro. Favor de comunicarse con soporte.",
                          footer: ""
                      });
                  }).then(() => {
                      window.dialogLoader.hide();                      
                  });
          }
              
    },

    buscarArticulo: function(){
      if(this.codigo!= null && this.codigo_articulo!= null && this.codigo_articulo!= ''){
          //TRASPASO
          if(this.codigo.toString().trim().startsWith('T') && this.codigo_articulo!= null){ //Es TRASPASO

            window.dialogLoader.show('Espere un momento por favor..');
            //Buscar codigo en los articulos del modelo
            //Si lo encuentra agregar 1 a cantidad_salida y guardar
            //Si no lo encuentra mostrar alerta

            var buscar_codigo = this.codigo_articulo.toString().trim();
            var encontrado = 0;
            this.model.articulos.forEach(e => {
              if(e.codigo_articulo.includes(buscar_codigo)){
                  encontrado = 1;
                  if(this.model.estatus == "Pendiente")
                    this.model.estatus = "En Proceso de Salida";

                  if(e.cantidad_salida!=null && e.cantidad_salida!="")
                    e.cantidad_salida = parseFloat(e.cantidad_salida) + parseFloat(1);
                  else
                    e.cantidad_salida = parseFloat(1);

                  window.axios
                      .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
                      .then(response => {
                          this.model._rev = response.data.rev;
                          this.recargarArticulos();
                      })
                      .catch(error => {
                          this.$swal({
                                  type: "error",
                                  title: "¡Operación no Permitida!",
                                  text: "Ocurrió un error al guardar el registro. Favor de comunicarse con soporte.",
                                  footer: ""
                              });
                      }).then(() => {
                          window.dialogLoader.hide();
                          this.codigo_articulo = "";
                          this.$nextTick(() => this.$refs.ingresaarticulo.focus());
                      });
              }

            });

            if(encontrado == 0){
              window.dialogLoader.hide();
              this.$swal({
                  type: "info",
                  title: "¡No encontrado!",
                  text: "Artículo no encontrado. Favor de verificar.",
                  footer: ""
              }).then(()=>{
                  this.codigo_articulo = "";
                  this.$nextTick(() => this.$refs.ingresaarticulo.focus());
              });
            }



          //VENTA
          } else if (this.codigo.toString().trim().startsWith('V') && this.codigo_articulo!= null){ //Es VENTA

              window.dialogLoader.show('Espere un momento por favor..');
              //Buscar codigo en los articulos del modelo
              //Si lo encuentra agregar 1 a cantidad_salida y guardar
              //Si no lo encuentra mostrar alerta

              var buscar_codigo = this.codigo_articulo.toString().trim();
              var encontrado = 0;

              this.model.articulos.forEach(e => {
              if(e.codigo_articulo.includes(buscar_codigo)){
                  encontrado = 1;

                  if(e.cantidad_salida!=null && e.cantidad_salida!="")
                    e.cantidad_salida += parseFloat(1);
                  else
                    e.cantidad_salida = parseFloat(1);

                  window.axios
                      .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
                      .then(response => {
                          this.model._rev = response.data.rev;
                          this.recargarArticulos();
                      })
                      .catch(error => {
                          this.$swal({
                                  type: "error",
                                  title: "¡Operación no Permitida!",
                                  text: "Ocurrió un error al guardar el registro. Intente nuevamente.",
                                  footer: ""
                              });
                      }).then(() => {
                          window.dialogLoader.hide();
                          this.codigo_articulo = "";
                          this.$nextTick(() => this.$refs.ingresaarticulo.focus());
                      });
              }
            });

              if(encontrado == 0){
                window.dialogLoader.hide();
                this.$swal({
                    type: "info",
                    title: "¡No encontrado!",
                    text: "Artículo no encontrado. Favor de verificar.",
                    footer: ""
                }).then(()=>{
                    this.codigo_articulo = "";
                    this.$nextTick(() => this.$refs.ingresaarticulo.focus());
                });
              }
          }
          else {
            this.$nextTick(() => this.$refs.ingresaarticulo.focus());
          }
      } else {//buscar código por nombre
          this.modalBuscar = true;
          this.buscar_codigo_articulo = "";
          var self = this;
          setTimeout(function(){
              self.$nextTick(() => self.$refs.selectarticulo.focus());
          },300);

      }
    },

    cerrarModal: function(){
      this.modalBuscar = false;
      var self = this;
          setTimeout(function(){
              self.$nextTick(() => self.$refs.ingresaarticulo.focus());
          },300);
    },

    recargarArticulos: function(){
      if (this.tipo_orden == "Traspaso")
        this.registros.items = this.model.articulos;
      else if (this.tipo_orden == "Venta"){
        this.registros.items = this.model.articulos;
      }

    },
    buscarCodigo: function(){
      //TRASPASO
      this.observacion = "";
      if(this.codigo!= null && this.codigo.toString().trim().startsWith('T')){ //Es TRASPASO
        this.tipo_orden = "Traspaso";

        window.dialogLoader.show('Espere un momento por favor..');
        let where = {
          "type":"lotes_traspasos",
          "id_sucursal_origen": this.sucursal_activa,
          "clave_traspaso": this.codigo.toString().trim(),
        };
        axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            selector: where,
            sort: [{"fecha": "desc"}],
            use_index:"_design/31b6292a0193c8c880121b27bb08926e4d17a985"
          },
        }).then(response => {
            if (response.data.docs.length) {
                if(["Pendiente", "En Proceso de Salida"].includes(response.data.docs[0].estatus)){
                    this.model = response.data.docs[0];
                    var articulos = response.data.docs[0].articulos;
                    this.registros.items = articulos;
                    setTimeout(() => {
                        this.$nextTick(() => this.$refs.ingresaarticulo.focus());
                    }, 300);
                } else {
                    this.registros.items = [];
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ya se dió salida a la orden ingresada. Favor de verificar.",
                        footer: ""
                    }).then(()=>{
                        this.codigo = "";
                        this.$nextTick(() => this.$refs.ingresacodigo.focus());
                    });
                }
            } else {
                this.registros.items = [];
                this.$swal({
                    type: "info",
                    title: "¡No Encontrado!",
                    text: "No se encontraron registros para el código ingresado. Favor de verificar.",
                    footer: ""
                }).then(()=>{
                    this.codigo = "";
                    this.$nextTick(() => this.$refs.ingresacodigo.focus());
                });

            }
        })
        .catch(error => {
          console.log(error)
          this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al buscar el código. Favor de comunicarse con soporte.",
              footer: ""
            });
        }).then(()=>{
          window.dialogLoader.hide();
        });

      //VENTA
      } else if (this.codigo!= null && this.codigo.toString().trim().startsWith('V')){ //Es VENTA
        this.tipo_orden = "Venta";

        window.dialogLoader.show('Espere un momento por favor..');
          let where = {
            "ticket": this.codigo.toString().trim(),
          };
          //console.log(where);

          axios({
            method: 'POST',
            url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
            withCredentials: true,
            data: {
              selector: where,              
              use_index:"_design/1e7fd82e52f4e17082b520be8b8d4fc49ee8ec41"
            },
          }).then(response => {
              if (response.data.docs.length) {
                  if (response.data.docs[0].sucursal._id != this.sucursal_activa){
                    this.registros.items = [];
                      this.$swal({
                          type: "error",
                          title: "¡Operación no Permitida!",
                          text: "La orden no pertenece a la Sucursal en sesión. Favor de verificar.",
                          footer: ""
                      }).then(()=>{
                          this.codigo = "";
                          this.$nextTick(() => this.$refs.ingresacodigo.focus());
                      });
                  } else if(["Finalizada", "Facturada", "Factura Global"].includes(response.data.docs[0].estatus)){
                      var model = response.data.docs[0];

                      //Si hay pendientes, se muestran los articulos
                      var pendientes = 0;
                      model.articulos.forEach( a => {
                          if(a.estatus == 'Pendiente')
                              pendientes += 1;
                      });
                      
                      //Si ya tiene fecha_salida quiere decir que ya se le dió salida antes
                      if(model.fecha_salida && model.fecha_salida!="" && pendientes == 0){
                        this.registros.items = [];
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ya se dió salida a la orden ingresada. Favor de verificar.",
                            footer: ""
                        }).then(()=>{
                            this.codigo = "";
                            this.$nextTick(() => this.$refs.ingresacodigo.focus());
                        });
                      } else {
                        this.model = model;
                        this.registros.items = model.articulos;
                        setTimeout(() => {
                            this.$nextTick(() => this.$refs.ingresaarticulo.focus());
                        }, 300);
                      }
                      
                  } else {
                      this.registros.items = [];
                      this.$swal({
                          type: "error",
                          title: "¡Operación no Permitida!",
                          text: "La orden no se ha pagado o fue cancelada. Favor de verificar.",
                          footer: ""
                      }).then(()=>{
                          this.codigo = "";
                          this.$nextTick(() => this.$refs.ingresacodigo.focus());
                      });
                  }
              } else {
                  this.registros.items = [];
                  this.$swal({
                      type: "info",
                      title: "¡No Encontrado!",
                      text: "No se encontraron registros para el código ingresado. Favor de verificar.",
                      footer: ""
                  }).then(()=>{
                      this.codigo = "";
                      this.$nextTick(() => this.$refs.ingresacodigo.focus());
                  });

              }
          })
          .catch(error => {
            console.log(error)
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al buscar el código. Favor de comunicarse con soporte.",
                footer: ""
              });
          }).then(()=>{
            window.dialogLoader.hide();
          });

      } else {
        this.$swal({
              type: "info",
              title: "¡Código no reconocido!",
              text: "Ingrese nuevamente el código.",
              footer: ""
            }).then(()=>{
                this.codigo="";
                this.$nextTick(() => this.$refs.ingresacodigo.focus());
            });
      }

    },
    validaCantidadFloat: function(value) {
      const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
      if (value != null && !pattern.test(value)) {
        return "Cantidad no válida"
      } else if (value != null && value != ""){
        if(parseFloat(value)<0)
          return "No debe ser negativo";
        else if(parseFloat(value) > 100000)
          return "Cantidad demasiado grande";
      }
      return true;

    },

    buscarOtro: function(){
      this.registros.items = [];
      this.codigo_articulo = "";
      this.codigo = "";
      this.model._id = "";
      this.$nextTick(() => this.$refs.ingresacodigo.focus());
    },

    cancelarComprometido: function(articulo){
        var self = this;
       return new Promise(function(resolve, reject) {
              var lotes = articulo.lotes;
              // llamar cancelarComprometido con los lotes guardados
              //Regresar el inventario comprometido al actual
              window.funciones_lotes.cancelarComprometido(lotes).then(result =>{

                  var id_sucursal = "";
                  if(self.tipo_orden == "Traspaso")
                    id_sucursal = self.model.id_sucursal_origen;
                  else if (self.tipo_orden == "Venta")
                    id_sucursal = self.model.sucursal._id;

                  let dataAfecta = {
                      id_sucursal: id_sucursal,  //Requerido
                      id_articulo: articulo.id_articulo,  //Requerido
                      cantidad: parseFloat(articulo.cantidad_salida)*-1, //Requerido   Se hace negativo porque se descuenta de la sucursal origen
                      es_super: 1, //Requerido  - En este caso super siempre es 1 ya que el descuento se hace directo
                  };

                  window.funciones_lotes.ajusteInventario(dataAfecta).then(result =>{


                        self.model.articulos.forEach(e => {
                            if(e.id_articulo == articulo.id_articulo){
                                e.lotes = result;
                                e.estatus = "Salida";
                            }
                        });


                      window.axios
                          .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + self.model._id + '?conflicts=true', self.model)
                          .then(response => {
                              self.model._rev = response.data.rev;
                              return resolve(true);
                          })
                          .catch(error => {
                              return reject(error);
                          });

                  }).catch(err => {
                      return reject(err);
                  });


              }).catch( error => {
                  return reject(error);
              });
       });
    },

    finalizarComprometido: function(articulo){
        var self = this;
       return new Promise(function(resolve, reject) {
          var lotes = articulo.lotes;
          //Afectar inventario origen
          window.funciones_lotes.finalizarComprometido(lotes).then(result =>{

                self.model.articulos.forEach(e => {
                    if(e.id_articulo == articulo.id_articulo){
                        e.lotes = result;
                        e.estatus = "Salida";
                    }
                });


                window.axios
                    .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + self.model._id + '?conflicts=true', self.model)
                    .then(response => {
                        self.model._rev = response.data.rev;
                        return resolve(true);
                    })
                    .catch(error => {
                        return reject(error);
                    });
            }).catch( error => {
                return reject(error);
            });
       });
    },

    getDiferencias: function(){
        var diferencias = 0;
        var suma_salidas = 0;

        this.model.articulos.forEach( a => {
            suma_salidas += a.cantidad_salida;
            if(a.cantidad != a.cantidad_salida)
                diferencias += 1;
        });
        
        return [diferencias, suma_salidas];
    },

    finalizarSalida: function(){
        //Revisar que todas las cantidades coincidan, sino mandar confirmación si desea continuar
        var diferencias = this.getDiferencias();
        if(diferencias[1] == 0){
          this.$swal({
                type: "error",
                title: "¡Operación No Permitida!",
                text: "No ha señalado la salida de ningún artículo. Favor de Verificar.",
                footer: "",                
            });
        }
        else if(diferencias[0] > 0){
            this.$swal({
                type: "info",
                title: "Cuidado",
                text: "Hay diferencias en las cantidades. ¿Aún asi desea finalizar la salida?",
                footer: "",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Si",
                cancelButtonText: "No"
            }).then(resultado => {
                if (resultado.value) {

                    this.modalObservacion = true;
                }
            });
        } else {
            this.finalizar();
        }
    },

    finalizarObservacion:function(){
      if(this.$refs.formobservacion.validate()) {
        this.finalizar();
        this.modalObservacion=false;
      }
    },

    finalizar: async function(){
        window.dialogLoader.show('Espere un momento por favor..');
        var errores = [];
        //Comparar las cantidades de cada articulo

        for(var k in this.model.articulos){
            if(this.model.articulos[k].estatus=="Pendiente"){
                if(this.model.articulos[k].cantidad != this.model.articulos[k].cantidad_salida){
                    if(parseFloat(this.model.articulos[k].cantidad_salida) > 0){
                      //Si la cantidad_salida es diferente a la cantidad
                      //se cancela comprometido de cantidad, y se afectainventario con cantidad_salida con es_super=1
                      await this.cancelarComprometido(this.model.articulos[k]).then(result => {
                          //nada
                      }).catch(err => {
                          console.log(this.model.articulos[k].nombre_articulo);
                          console.log(err);
                          errores.push(err);
                      });
                    }
                } else {
                    //Si la cantidad salida es igual a la cantidad
                    //Se finaliza comprometido con la cantidad
                    await this.finalizarComprometido(this.model.articulos[k]).then(result => {
                        //nada
                    }).catch(err => {
                        console.log(this.model.articulos[k].nombre_articulo);
                        console.log(err);
                        errores.push(err);
                    });
                }
            }

        }


        if(errores.length == 0){

            if(this.tipo_orden == "Traspaso"){
              //Guardar model y poner estatus en "Salida de Origen"
              this.model.estatus = "Salida de Origen";
            }
            //En ventas el estatus se queda igual
            this.model.usuario_salida = this.$local_storage.get("sb_usuario");
            this.model.fecha_salida = window.moment().format("YYYY-MM-DDTHH:mm:ss"); 
            this.model.observacion_salida = this.observacion;

            window.axios
                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
                .then(response => {
                    this.model._rev = response.data.rev;
                    window.dialogLoader.showSnackbar('El proceso finalizó correctamente... ', {
                        color: 'success'
                    });
                    this.registros.items = [];
                    this.codigo_articulo = "";
                    this.codigo = "";
                    this.model._id = "";
                    this.$nextTick(() => this.$refs.ingresacodigo.focus());
                })
                .catch(error => {
                  console.log(error);
                    this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al finalizar el proceso. Por favor intente nuevamente.",
                            footer: ""
                        });
                }).then(() => {
                    window.dialogLoader.hide();
                });
        } else {
          console.log(errores);
            window.dialogLoader.hide();
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al finalizar el proceso. Intente nuevamente.",
                footer: ""
            });
        }

    },


  }
};
</script>
<style scoped>
.v-card__text >>> .bg-mal {
    background-color: #fcb7af !important;
}
.v-card__text >>> .bg-bien {
    background-color: #d8f8e1 !important;
}
</style>
